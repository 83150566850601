import React from "react"
import { GatsbyImage } from 'gatsby-plugin-image'
import { graphql, useStaticQuery } from 'gatsby'
import SectionTitle from "./sectionTitle";

const Service = ({ children }) => {

  const services = useStaticQuery(graphql`
  query Services {
    allServicesJson {
      edges {
        node {
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
          description
          id
          title
        }
      }
    }
  }   
`) 


  return (
    <section className="py-0">
      <div className="container mx-auto">
        <SectionTitle
          wrapperClassName="pt-16 pb-16"
          alignCenter={true}
          title={['Bringing people closer', <span className="inline-block md:hidden lg:inline-block">&nbsp;</span>, <br className="hidden md:block lg:hidden"/>,'to information.']}
          minititle="What we do"
          type="h2"
          invert={false}
          childrenClassName="lg:w-3/5 mx-auto"
          buttonText=""
          buttonType=""
          buttonLink=""
        >
          <p>
            We specialise in creating digital products that bring people closer to information. Our process refined over the years enables us to produce high-quality user-focused products consistently.
          </p>
        </SectionTitle>
      </div>

      <div className="flex flex-row flex-wrap space-y-4 lg:space-y-0 lg:space-x-4 px-4 mb-4">
        {services.allServicesJson.edges.map((service, index) => {
          const item = service.node
          return (
            <div className="w-full lg:flex-1">
              <div className={`bg-service bg-service-${index + 1} px-8 pt-10`}>
                <h4 className="mb-2 text-center font-normal">{item.title}</h4>
                <p className="md:w-3/4 m-auto text-sm md:text-base text-center leading-relaxed -mb-5 sm:mb-0">{item.description}</p>
                <div className="img-container">
                  <GatsbyImage
                    draggable={false}
                    objectFit="contain"
                    image={item.image.childImageSharp.gatsbyImageData}
                    alt={item.title}
                  />
                </div>
                <div className="img-height"></div>
              </div>
            </div>
          )
        })}
      </div>
    </section>
  );
};

export default Service;
